import { Input } from "@chakra-ui/react";
import "./InputBoilerplate.css";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { useState } from "react";

function InputBoilerplate({
  label,
  type,
  value,
  placeholder,
  setState,
  inputIsTextArea,
  setError,
  disabled,
  step,
}) {
  const [isShow, setIsShow] = useState(true);
  const [customInputType, setCustomInputType] = useState(type);

  function handleShow() {
    setIsShow(false);
    setCustomInputType("text");
  }

  function handleHidden() {
    setIsShow(true);
    setCustomInputType("password");
  }

  function ViewIcon() {
    return isShow ? (
      <RiEyeLine onClick={() => handleShow()} className="eye-icon-login" />
    ) : (
      <RiEyeOffLine onClick={() => handleHidden()} className="eye-icon-login" />
    );
  }

  return (
    <div className="input_group">
      {label && (
        <label className="input_field_label caption bold">{label}</label>
      )}
      {!inputIsTextArea && (
        <div className="input-group">
          <Input
            // className='input_field'
            className="test"
            bg="white"
            type={customInputType}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              setState(e.target.value);
              setError && setError("");
            }}
            size="lg"
            py="4"
            h="auto"
            fontSize="small"
            step={step}
            borderColor={"gray.300"}
            isDisabled={disabled}
          />
          {type === "password" && <ViewIcon />}
        </div>
      )}
      {inputIsTextArea && (
        <textarea
          className="input_field"
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            setState(e.target.value);
            setError && setError("");
          }}
        />
      )}
    </div>
  );
}

export default InputBoilerplate;
