import { Image } from "@chakra-ui/react";
import React from "react";
import ChebLogo from "../../../../../../Assets/cheb-logo.svg";

export default function SidebarImage() {
  return (
    <Image
      // src="https://store.cheb.antopolis.xyz/assets/logo/cheb-logo.svg"
      src={ChebLogo}
      alt="logo"
      width={100}
    />
  );
}
