import { useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ScreenHolder from "../../../../Partials/Layouts/ScreenHolder/ScreenHolder";
import "../../Auth.css";
import useAxiosAuthInstance from "../../../../../Utilities/Hooks/AxiosInstanceHooks/useAxiosAuthInstance";
import { toast } from "react-toastify";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import { jwtDecode } from "jwt-decode";

function RecoverPassThree() {
  const [newPassword, setnewPassword] = useState("");
  const [newPassword2, setnewPassword2] = useState("");
  const [isShowNewPassword, setIsShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [isValidToken, setIsValidToken] = useState(true);

  const { token } = useParams();
  const navigate = useNavigate();
  const axiosAuthInstance = useAxiosAuthInstance();

  useEffect(() => {
    if (!token || isTokenExpired(token)) {
      setIsValidToken(false);
      toast.error("Reset password link expired");
      navigate("/recover-pass-one"); // Redirect to login if token is expired or invalid
    }
  }, [navigate]);

  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

      return decoded.exp < currentTime; // true if expired, false otherwise
    } catch (error) {
      console.error("Invalid token:", error);
      return true; // Treat invalid tokens as expired
    }
  };
  const toggleNewPasswordVisibility = () =>
    setIsShowNewPassword((prev) => !prev);

  const toggleConfirmPasswordVisibility = () =>
    setIsShowConfirmPassword((prev) => !prev);

  // Password validation checks
  const validations = useMemo(
    () => ({
      length: newPassword.length >= 8,
      uppercase: /[A-Z]/.test(newPassword),
      lowercase: /[a-z]/.test(newPassword),
      number: /\d/.test(newPassword),
      specialChar: /[@$!%#*?&]/.test(newPassword),
    }),
    [newPassword]
  );

  // Check if all validations are fulfilled
  const areAllValidationsFulfilled = useMemo(
    () => Object.values(validations).every((isValid) => isValid),
    [validations]
  );

  // Check if button should be disabled
  const isDisabled = useMemo(
    () =>
      !newPassword?.trim() ||
      !newPassword2?.trim() ||
      !areAllValidationsFulfilled,
    [newPassword, newPassword2, areAllValidationsFulfilled]
  );

  async function handleSubmit(e) {
    e.preventDefault();

    if (!newPassword) {
      toast.error("Password is required.", { autoClose: 1500 });
      return;
    }

    if (!newPassword2) {
      toast.error("Confirm password is required.", { autoClose: 1500 });
      return;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%#*?&])[A-Za-z\d@$!%#*?&]{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      toast.error(
        "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.",
        { autoClose: 1500 }
      );
      return;
    }

    if (newPassword !== newPassword2) {
      toast.error("Password Must Be Similiar", { autoClose: 1500 });
      return;
    }

    const formBody = {
      token,
      newPassword: newPassword,
    };

    const changePassword = await axiosAuthInstance.patch(
      "resetSellerPassword",
      formBody
    );

    if (changePassword?.status == 201) {
      toast.error(changePassword?.data?.message);
      navigate("/recover-pass-one");
    } else {
      if (changePassword) {
        toast.success("Password reset successfully.");
        setnewPassword("");
        setnewPassword2("");
        navigate("/login");
      }
    }
  }

  return (
    <ScreenHolder>
      <div className="form_wrapper">
        <h3>Recover Password</h3>
        <form onSubmit={handleSubmit}>
          <div className="input_group">
            <label className="input_field_label caption bold">
              New Password
            </label>
            <div className="relative input-group">
              <input
                type={isShowNewPassword ? "text" : "password"}
                className="input_field body_text"
                placeholder="Enter password"
                value={newPassword}
                onChange={(e) => setnewPassword(e.target.value)}
              />
              {isShowNewPassword ? (
                <RiEyeOffLine
                  onClick={toggleNewPasswordVisibility}
                  className="eye-icon"
                />
              ) : (
                <RiEyeLine
                  onClick={toggleNewPasswordVisibility}
                  className="eye-icon"
                />
              )}
            </div>
          </div>
          <div className="input_group">
            <label className="input_field_label caption bold">
              Confirm Password
            </label>
            <div className="relative input-group">
              <input
                type={isShowConfirmPassword ? "text" : "password"}
                className="input_field body_text"
                placeholder="Enter password"
                value={newPassword2}
                onChange={(e) => setnewPassword2(e.target.value)}
              />
              {isShowConfirmPassword ? (
                <RiEyeOffLine
                  onClick={toggleConfirmPasswordVisibility}
                  className="eye-icon"
                />
              ) : (
                <RiEyeLine
                  onClick={toggleConfirmPasswordVisibility}
                  className="eye-icon"
                />
              )}
            </div>
          </div>

          <button
            type="submit"
            className="btn_text submit"
            disabled={isDisabled}
          >
            Change Password
          </button>
        </form>
        <Link to="/login" className="btn_text forgot_pass">
          Back to Login
        </Link>

        <ul className="instruction-ul">
          <li className={validations.length ? "success_text" : "error_text"}>
            Password must be at least 8 characters long.
          </li>
          <li className={validations.uppercase ? "success_text" : "error_text"}>
            Password must include an uppercase letter.
          </li>
          <li className={validations.lowercase ? "success_text" : "error_text"}>
            Password must include a lowercase letter.
          </li>
          <li className={validations.number ? "success_text" : "error_text"}>
            Password must include a number.
          </li>
          <li
            className={validations.specialChar ? "success_text" : "error_text"}
          >
            Password must include a special character.
          </li>
        </ul>
      </div>
    </ScreenHolder>
  );
}

export default RecoverPassThree;
