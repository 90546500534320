import { useEffect, useState } from "react";
import { RiDashboardLine } from "react-icons/ri";
import { MdOutlinePendingActions } from "react-icons/md";
import { RiMoneyEuroBoxLine } from "react-icons/ri";
import { AiTwotoneSetting } from "react-icons/ai";

import SidebarItem from "./SidebarItem/SidebarItem";
import LogoutButton from "./LogoutButton";
import "./SidebarItems.css";

import { VStack, Button, Collapse } from "@chakra-ui/react";
import useAxiosInstance from "../../../../../Utilities/Hooks/useAxiosInstance";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useDisconnect } from "wagmi";

const Menu_Item = [
  {
    title: "Dashboard",
    icon: <RiDashboardLine />,
  },
  {
    title: "My Stores",
    icon: <MdOutlinePendingActions />,
  },
  // {
  //   title: "Payment Card Info",
  //   icon: <RiMoneyEuroBoxLine />,
  // },
  {
    title: "Payment Settings",
    icon: <RiMoneyEuroBoxLine />,
    isPaymentSettings: true,
  },
];

function SidebarItems() {
  const axiosInstance = useAxiosInstance();
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogout = () => {
    console.log("disconnetc calling");
    disconnect();
  };

  const togglePaymentSettings = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (address) {
      async function updateWalletAddress() {
        try {
          const { data } = await axiosInstance.patch("wallet/address-update", {
            walletAddress: address,
          });
        } catch (error) {
          console.error("Error updating wallet address:", error);
        }
      }
      updateWalletAddress();
    }
  }, [address]);

  const handlePaymentConfig = async () => {
    try {
      setIsLoading(true);
      const res = await axiosInstance.get("payment/config");
      const url = res.data.data.url;

      console.log("url", url);

      // Redirect to the URL
      if (url) {
        setIsLoading(false);
        window.location.href = url;
        // window.open(url);
      } else {
        setIsLoading(true);
        console.error("URL not found in the response");
      }
    } catch (error) {
      setIsLoading(true);
      console.error(error);
    }
    // navigate("/");
  };

  // return (
  //   <VStack h="100%" w="100%" px="1rem" justify="space-between" pb="1rem">
  //     <VStack w="100%" h="100%">
  //       {Menu_Item.map((menu_item) => (
  //         <SidebarItem key={`menu_item_${menu_item.title}`} {...menu_item} />
  //       ))}
  //     </VStack>
  //     <ConnectButton />
  //     <LogoutButton onLogout={handleLogout} />
  //   </VStack>
  // );

  return (
    <VStack h="100%" w="100%" px="1rem" justify="space-between" pb="1rem">
      <VStack w="100%" h="100%">
        {Menu_Item.map((menu_item) =>
          !menu_item.isPaymentSettings ? (
            <SidebarItem key={`menu_item_${menu_item.title}`} {...menu_item} />
          ) : (
            <VStack key="payment-settings" w="100%">
              <Button
                w="100%"
                leftIcon={menu_item.icon}
                justifyContent="space-between"
                onClick={togglePaymentSettings}
                bg="transparent"
                _hover={{ bg: "#322A5D30" }}
              >
                {menu_item.title}
                {open ? <AiTwotoneSetting /> : <AiTwotoneSetting />}
              </Button>

              <Collapse in={open} animateOpacity>
                <VStack pl={4} w="100%">
                  {/* Nested Payment Config */}
                  <SidebarItem
                    title="Payment Config"
                    icon={<RiMoneyEuroBoxLine />}
                    onClick={handlePaymentConfig}
                  />
                </VStack>
              </Collapse>
            </VStack>
          )
        )}
      </VStack>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          // Ensure the button state is mounted and authentication is loaded
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === "authenticated");

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <button
                      onClick={openConnectModal}
                      type="button"
                      style={{
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        border: "2px solid",
                        padding: "12px 20px",
                        borderRadius: "12px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textAlign: "center",
                        transition: "all 0.3s ease",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#333";
                        e.target.style.transform = "scale(1.05)";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "var(--primary-color)";
                        e.target.style.transform = "scale(1)";
                      }}
                      onFocus={(e) => {
                        e.target.style.boxShadow =
                          "0 0 0 3px rgba(255, 255, 255, 0.6)";
                      }}
                      onBlur={(e) => {
                        e.target.style.boxShadow = "none";
                      }}
                    >
                      Connect Wallet
                    </button>
                  );
                }

                if (chain.unsupported) {
                  return (
                    <button
                      onClick={openChainModal}
                      type="button"
                      style={{
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        border: "2px solid",
                        padding: "12px 20px",
                        borderRadius: "12px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textAlign: "center",
                        transition: "all 0.3s ease",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#333";
                        e.target.style.transform = "scale(1.05)";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "var(--primary-color)";
                        e.target.style.transform = "scale(1)";
                      }}
                      onFocus={(e) => {
                        e.target.style.boxShadow =
                          "0 0 0 3px rgba(255, 255, 255, 0.6)";
                      }}
                      onBlur={(e) => {
                        e.target.style.boxShadow = "none";
                      }}
                    >
                      Wrong network
                    </button>
                  );
                }

                return (
                  <div style={{ display: "flex", gap: 12 }}>
                    {/* Chain Button */}
                    {/* <button
                      onClick={openChainModal}
                      type="button"
                      style={{
                        backgroundColor: 'var(--primary-color)',
                        color: 'white',
                        border: '2px solid',
                        padding: '12px 20px',
                        borderRadius: '12px',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        textAlign: 'center',
                        transition: 'all 0.3s ease',
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#333';
                        e.target.style.transform = 'scale(1.05)';
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = 'var(--primary-color)';
                        e.target.style.transform = 'scale(1)';
                      }}
                      onFocus={(e) => {
                        e.target.style.boxShadow = '0 0 0 3px rgba(255, 255, 255, 0.6)';
                      }}
                      onBlur={(e) => {
                        e.target.style.boxShadow = 'none';
                      }}
                    >
                      {chain.hasIcon && (
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 18,
                            height: 18,
                            borderRadius: '50%',
                            overflow: 'hidden',
                            marginRight: 8,
                          }}
                        >
                          {chain.iconUrl && (
                            <img
                              alt={chain.name ?? 'Chain icon'}
                              src={chain.iconUrl}
                              style={{ width: 18, height: 18 }}
                            />
                          )}
                        </div>
                      )}
                      {chain.name}
                    </button> */}

                    {/* Account Button */}
                    <button
                      onClick={openAccountModal}
                      type="button"
                      style={{
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        border: "2px solid",
                        padding: "12px 20px",
                        borderRadius: "12px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        cursor: "pointer",
                        textAlign: "center",
                        transition: "all 0.3s ease",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                      }}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#333";
                        e.target.style.transform = "scale(1.05)";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "var(--primary-color)";
                        e.target.style.transform = "scale(1)";
                      }}
                      onFocus={(e) => {
                        e.target.style.boxShadow =
                          "0 0 0 3px rgba(255, 255, 255, 0.6)";
                      }}
                      onBlur={(e) => {
                        e.target.style.boxShadow = "none";
                      }}
                    >
                      {account.displayName}
                      {account.displayBalance
                        ? ` (${account.displayBalance})`
                        : ""}
                    </button>
                  </div>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
      <LogoutButton onLogout={handleLogout} />
    </VStack>
  );
}

export default SidebarItems;
